::-moz-selection,
::selection {
  background-color: rgba(map-get($theme, color), 0.3);
}

// :root {
//   @each $key, $value in $theme {
//     @if (type-of($value) != "map") {
//       --#{$cssVarPrefix}-#{$key}: #{$value};
//       // @debug --#{$cssVarPrefix}-#{$key}":" #{$value};
//     }
//   }
//   @each $key, $value in $color {
//     @if (type-of($value) != "map") {
//       --#{$cssVarPrefix}-#{$key}: #{$value};
//     }
//   }
// }

* {
  &,
  &:after,
  &:before {
    box-sizing: border-box;
  }
  @include scrollbar(8px, cssVar(color), rgba(map-get($color, dark), 0));
}

html,
body,
input,
select,
button {
  @include font($fontFamily);
}

html,
body {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: cssVar(bg);
  padding: env(safe-area-inset-top) env(safe-area-inset-#{$right}) env(safe-area-inset-bottom)
    env(safe-area-inset-#{$left});
}

html {
  font-size: $baseFontSize;
}

body {
  font-size: 1rem;
  overflow-x: hidden;
  color: cssVar(text);
  a {
    color: cssVar(link);
    &,
    & > * {
      outline: none;
    }
    &,
    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      text-decoration: none;
      color: cssVar(link);
    }
  }
}

img,
video {
  max-width: 100%;
  height: auto !important;
}

p {
  margin: sides(0 0 10px);
}

figure {
  margin: 0;
}
