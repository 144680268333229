.sideNav {
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  height: 100%;
  position: fixed;
  min-height: 100vh;
  overflow-x: hidden;
  width: $sideBarWidth;
  flex: 0 0 $sideBarWidth;
  color: cssVar(sidebarText);
  // min-width: $sideBarWidth;
  transform: translateX(-100%);
  background-color: cssVar(sidebarBG);
  &.opened {
    opacity: 1;
    position: sticky;
    transform: translateX(0%);
  }
  // .p-panelmenu .p-panelmenu-header > a{
  //   background: cssVar(sidebarBG);
  // }
  .p {
    &-panelmenu {
      &-header {
        & > a {
          color: cssVar(sidebarText);
          border-color: cssVar(brdr);
          background: cssVar(sidebarBG);
        }
      }
    }
  }

  .loginText {
    font-size: 12px;
    margin-bottom: 6px;
    color: cssVar(sidebarText);
  }
}
