header.header {
  top: 0;
  right: 0;
  z-index: 999;
  height: 77px;
  padding: 1em;
  display: flex;
  position: fixed;
  align-items: center;
  color: cssVar(header-text);
  // background: cssVar(header-bg);
  background: #eff3f8;
  // border-bottom: 1px solid rgba(112,112,112,0.2);
  justify-content: space-between;
  // box-shadow: 0 0 4px rgba(#{cssVar(dark, $color)}, 0.25);

  .p-avatar {
    color: cssVar(header-text);
    background-color: cssVar(header-link);
  }
  &.inner{
    // background: cssVar(header-bg);
    &:before{
      content : "";
      position: absolute;
      bottom  : 0;
      left:20px;
      right: -50px;
      width: calc(100% - 50px);
      height: 1px;
      border-bottom: 1px solid rgba(112,112,112,0.2);
    }
  }

  @media screen and (max-width: 991px) {
    // padding-left: 1;
    justify-content: flex-start;
  }


}
