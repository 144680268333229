@use "sass:meta";
@use "sass:string";

$cssVarPrefix: "app";

$fontFamily: "Source Sans Pro", sans-serif;
// $scFontFamily: "LetterGothicMTStd", monospace;

$icomoon-font-path: "~/assets/icons";

// $dir: ltr;
// $dir: rtl;

// COMMON FONT CHART
// -----------------
$fontUL: 100;
$fontT: 200;
$fontL: 300;
$font: 400;
$fontM: 500;
$fontSB: 600;
$fontB: 700;
$fontH: 800;
$fontBK: 900;

$theme: (
  write: #fff,
  matBg: #eff3f8,
  baseFontSize: 14px,
  rad: 2px border-radius,
  fontFamily: $fontFamily,
  // scFontFamily: $scFontFamily,
  bg: #eef3f9,
  // surface-ground,
  text: #6c757d,
  // text-color,
  link: #2196f3,
  // primary-color,
  brdr: #dee2e6,
  // surface-border,
  color: #2196f3,
  // primary-color,
  sidebarBG: #fff,
  sidebarText: #333,
  login-text: #fff,
  login-bg: #353a40,
  footer-text: #fff,
  footer-bg: #353a40,
  header-bg: #363b41,
  header-text: #fff,
  header-link: #be5532,
  table-head: #0b109f,
  types: (
    dark: #000,
    light: #fff,
    info: #1492e6,
    theme: #2196f3,
    accent: #025a91,
    danger: #b52154,
    success: #1a9c00,
    warning: #d87a5a,
    default: #eef3fa,
    primary: #2196f3,
  ),
  contrast: (
    dark: #fff,
    info: #fff,
    accent: #fff,
    primary: #fff,
    success: #fff,
    warning: #fff,
    default: #000,
    danger: #fff,
    theme: #fff,
    light: #000,
  ),
);

$baseFontSize: cssVar(baseFontSize);

$fontFamily: var(--app-fontFamily, meta.inspect(map-get($theme, fontFamily)));

$color: map-get($theme, types);
$cont: map-get($theme, contrast);

$bgColor: (
  default #fff cssVar(text),
  primary map-get($color, primary) #fff,
  success map-get($color, success) #fff,
  info map-get($color, info) #fff,
  warning map-get($color, warning) #fff,
  danger map-get($color, danger) #fff,
  theme map-get($theme, color) #fff,
  accent map-get($color, accent) #fff
);

$sideBarWidth: 225px;

// /* Available CSS vars */
// $shade000:#ffffff !default;    //surface
// $shade100:#f8f9fa !default;    //header background
// $shade200:#e9ecef !default;    //hover background
// $shade300:#dee2e6 !default;    //border, divider
// $shade400:#ced4da !default;    //input border
// $shade500:#adb5bd !default;    //input icon
// $shade600:#6c757d !default;    //text secondary color
// $shade700:#495057 !default;    //text color
// $shade800:#343a40 !default;    //unused
// $shade900:#212529 !default;    //unused
// $primaryColor: #2196F3 !default;
// $primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
// $primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
// $primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
// $primaryTextColor: #ffffff !default;

// $highlightBg: #E3F2FD !default;
// $highlightTextColor: #495057 !default;
// $panelContentPadding:1rem !default;
// $inlineSpacing:.5rem !default;
// $borderRadius:3px !default;

// --surface-a: #{$shade000};
// --surface-b: #{$shade100};
// --surface-c: #{$shade200};
// --surface-d: #{$shade300};
// --surface-e: #{$shade000};
// --surface-f: #{$shade000};
// --text-color: #{$shade700};
// --text-color-secondary: #{$shade600};
// --primary-color: #{$primaryColor};
// --primary-color-text: #{$primaryTextColor};
// --font-family: #{$fontFamily};
// --surface-0: #ffffff;
// --surface-50: #fafafa;
// --surface-100: #f5f5f5;
// --surface-200: #eeeeee;
// --surface-300: #e0e0e0;
// --surface-400: #bdbdbd;
// --surface-500: #9e9e9e;
// --surface-600: #757575;
// --surface-700: #616161;
// --surface-800: #424242;
// --surface-900: #212121;
// --gray-50: #fafafa;
// --gray-100: #f5f5f5;
// --gray-200: #eeeeee;
// --gray-300: #e0e0e0;
// --gray-400: #bdbdbd;
// --gray-500: #9e9e9e;
// --gray-600: #757575;
// --gray-700: #616161;
// --gray-800: #424242;
// --gray-900: #212121;
// --content-padding: #{$panelContentPadding};
// --inline-spacing: #{$inlineSpacing};
// --border-radius: #{$borderRadius};
// --surface-ground: #f8f9fa;
// --surface-section: #ffffff;
// --surface-card: #ffffff;
// --surface-overlay: #ffffff;
// --surface-border: #dee2e6;
// --surface-hover: #e9ecef;
