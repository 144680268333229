@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%ellipsis {
  @include ellipsis;
}

%animateXSlow {
  @include prefix(
    (
      transition: all ease-in 1.3s,
    ),
    moz ms webkit
  );
}
%animateSlow {
  @include prefix(
    (
      transition: all ease-in 1s,
    ),
    moz ms webkit
  );
}
%animateNormal {
  @include prefix(
    (
      transition: all ease-in 0.6s,
    ),
    moz ms webkit
  );
}
%animateFast {
  @include prefix(
    (
      transition: all ease-in 0.3s,
    ),
    moz ms webkit
  );
}
%animateXFast {
  @include prefix(
    (
      transition: all ease-in 0.1s,
    ),
    moz ms webkit
  );
}

@include keyframes(spin) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@mixin nilSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
%nilSelect {
  @include nilSelect;
}

@mixin notaList {
  margin: 0;
  padding: 0;
  list-style: none;
}
%notaList {
  @include notaList;
}

// %clearfix {
//   @include clearfix();
// }

%inlineSpaceFix {
  font-size: 0;
  white-space: nowrap;
}

%scroll,
.scrollX,
.scrollY {
  overflow: scroll;
  overflow: auto;
  @include scrollbar(8px, cssVar(color), rgba(map-get($color, dark), 0));
}
%scrollX,
.scrollX {
  overflow-y: hidden;
  overflow-x: auto;
}
%scrollY,
.scrollY {
  overflow-x: hidden;
  overflow-y: auto;
}

@include keyframes(highlightFocusing) {
  0% {
    background: rgba(#ffd741, 0.5);
  }
  100% {
    background: rgba(#ffd741, 0);
  }
}

// .highlightFocusing {
//   @include animation("highlightFocusing 500ms 1 10ms");
//   @include prefix(
//     (
//       animation-fill-mode: both
//     ),
//     moz webkit ms
//   );
// }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.loader {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  border-color: rgba(cssVar(accent), 0.3);
  border-top-color: cssVar(accent) !important;
  animation: spin 2s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

.loading {
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    pointer-events: all;
  }
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1051;
    display: block;
    background: rgba(#000, 0.8);
  }
  &:after {
    z-index: 1060;
    @extend .loader;
    position: fixed;
    top: calc(50vh - 60px);
    left: calc(50% - 60px);
    border-color: rgba(#000, 0.3);
    border-top-color: cssVar(accent) !important;
  }
}
